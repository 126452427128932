import React, { forwardRef } from "react";
const RadioBox = React.forwardRef(
    ({ labelKey, checked, ...rest }, ref) => {
        return (
            <label className="group flex w-auto items-end justify-center text-base cursor-pointer mb-4 last:mb-4 last:mr-0 mr-8 ">
                <input
                    type="radio"
                    className="hidden form-radio w-4 h-4 border border-darkGrey text-heading rounded-full cursor-pointer transition duration-500 ease-in-out focus:ring-offset-0 hover:border-heading focus:outline-none focus:ring-0 focus-visible:outline-none checked:bg-red"
                    ref={ref}
                    checked={checked}
                    {...rest}
                />
                <span className="w-4 h-4 inline-block mr-2 rounded-full border border-darkGrey flex-no-shrink"></span>
                <span className="text-sm relative leading-none">
                    {labelKey}
                </span>
            </label>
        );
    }
);

export default RadioBox;
